<template>
    <div :class="['navbar', this.$store.state.navBarOpened ? 'opened' : 'hidden']">
        <div class="logo">arbitrCrm</div>
        <div class="list">
            <router-link to="/panel/calendar" class="item">
                <i class="material-icons">date_range</i>
                <div class="t">Мой календарь</div>
            </router-link>
            <router-link to="/panel/report" class="item" v-if="$store.state.isAdmin() || $store.state.isImplementor()">
                <i class="material-icons">data_thresholding</i>
                <div class="t">Отчет общий</div>
            </router-link>
            <router-link to="/panel/report_impl" class="item" v-if="$store.state.isImplementor()">
                <i class="material-icons">data_thresholding</i>
                <div class="t">Отчет исполнителя</div>
            </router-link>
            <router-link to="/panel/claim" class="item"
                         v-if="$store.state.isAdmin() || $store.state.isImplementor() || $store.state.isClaimant() || $store.state.isDefendant() || $store.state.isBuh()">
                <i class="material-icons">insert_drive_file</i>
                <div class="t">{{$store.state.isAdmin() ? 'Дела' : 'Мои дела'}}</div>
            </router-link>
            <router-link to="/panel/claim/create_claimant" class="item"
                         v-if="$store.state.isClaimant()">
                <i class="material-icons">note_add</i>
                <div class="t">Исковое заявление</div>
            </router-link>
            <router-link v-if="$store.state.isAgent()" to="/panel/claim-agent" class="item">
                <i class="material-icons">insert_drive_file</i>
                <div class="t">Дела агента</div>
            </router-link>
            <router-link to="/panel/claim/create" class="item"
                         v-if="$store.state.isAdmin() || $store.state.isImplementor()">
                <i class="material-icons">note_add</i>
                <div class="t">Создать дело</div>
            </router-link>
            <router-link to="/panel/appeal" class="item"
                         v-if="$store.state.isAdmin() || $store.state.isImplementor()">
                <i class="material-icons">description</i>
                <div class="t">Обращения</div>
            </router-link>
            <router-link to="/panel/user" class="item" v-if="$store.state.isAdmin() || $store.state.isImplementor()">
                <i class="material-icons">person</i>
                <div class="t">Пользователи</div>
            </router-link>
            <router-link to="/panel/user/create" class="item" v-if="$store.state.isAdmin() || $store.state.isImplementor()">
                <i class="material-icons">person_add</i>
                <div class="t">Создать пользователя</div>
            </router-link>
            <router-link to="/panel/city" class="item" v-if="$store.state.isAdmin()">
                <i class="material-icons">location_city</i>
                <div class="t">Города</div>
            </router-link>
            <router-link to="/panel/payment2" class="item"
                         v-if="$store.state.isAdmin() || $store.state.isImplementor() || $store.state.isBuh()">
                <i class="material-icons">attach_money</i>
                <div class="t">Оплаты</div>
            </router-link>
            <router-link to="/panel/claim/file_add" class="item"
                         v-if="$store.state.isDefendant() || $store.state.isClaimant()">
                <i class="material-icons">insert_drive_file</i>
                <div class="t">Подать документы</div>
            </router-link>
            <router-link to="/panel/user/notification" class="item"
                         v-if="$store.state.isAdmin()">
                <i class="material-icons">mail</i>
                <div class="t">Уведомления</div>
            </router-link>
            <router-link to="/panel/user/profile" class="item">
                <i class="material-icons">badge</i>
                <div class="t">Личный кабинет</div>
            </router-link>
        </div>
        <div class="dev">
            Разработано «<a href="https://smartsite.kz/" target="_blank">СмартСайт</a>»
        </div>
    </div>
</template>

<script>
export default {
    name: "navbar"
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/base";
$cl-nav-bg: #1a2232;
$cl-nav-text: #9ea4aa;
.navbar {
    background: $cl-nav-bg;
    color: $cl-nav-text;
    min-height: 100vh;
    position: relative;
    transition: 0.2s;
    overflow: hidden;
    @media (max-width: $max-width) {
        transition: none;
    }
    &.opened {
        width: 260px;
        @media (max-width: $max-width) {
            width: 100%;
            height: auto;
        }
    }
    &.hidden {
        width: 0;
    }
    @media (max-width: 1200px) {
        width: 100%;
        min-height: auto;
    }
    .logo {
        height: 60px;
        text-align: center;
        line-height: 60px;
        border-bottom: 1px solid $cl-nav-text;
        @media (max-width: 1200px) {
            display: none;
        }
    }
    .list {
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        flex-direction: column;
        @media (max-width: $max-width) {
            flex-direction: row;
        }
        .item {
            @extend .flex-st;
            gap:10px;
            text-decoration: none;
            line-height: 24px;
            padding: 9px 15px;
            border-radius: $radius;
            transition: 200ms;
            border-left: 4px solid transparent;
            .t {
                @media (max-width: 1200px) {
                    display: none;
                }
            }
            &.active {
                background: lighten($cl-nav-bg, 10%);
                color: #fff;
                border-color: #fff;
            }
            &:hover {
                @extend .active;
            }
        }
    }
    .dev {
        text-align: center;
        position: absolute;
        bottom: 20px;
        width: 100%;
        @media (max-width: 1200px) {
            display: none;
        }
    }
}
</style>